<template>
  <table :class="`w-100 p-3 table--default table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Foto</th>
        <th class="table__head--text">Item</th>
        <th class="table__head--text"></th>
        <th class="table__head--text">Order</th>
        <th class="table__head--text">Werkelijk</th>
        <th class="table__head--text">Verschil</th>
        <th class="table__head--text">Stadagen</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
      >
        <td class="table__cell--default table--thumbnail">
          <img
            :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.foto}/original`"
            class="thumbnail"
          />
        </td>
        <td  class="table__cell--default">
          <div>
            <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
            {{ item.soortnaam }}<br />
            {{ item.merknaam }}<br />
            {{ item.typenaam }}<br />
            {{ item.regjaar }}<br />
          </div>
        </td>
        <td  class="table__cell--default">
          <div>
            Inkoop<br />
            Kosten<br />
            Opbrengst<br />
            <hr />
            <strong>Resultaat</strong>
          </div>
        </td>
        <td  class="table__cell--default">
            <div>
                € {{ item.inkoopbedrag }}<br />
                € {{ item.geplandkosten }}<br />
                € {{ item.geplandopbrengst }}<br />
                <hr />
                <strong>€ {{ item.geplandresultaat }}</strong><br />
            </div>
        </td>
        <td  class="table__cell--default">
            <div>
                € {{ item.inkoopbedrag }}<br />
                € {{ item.werkelijkkosten }}<br />
                € {{ item.werkelijkopbrengst }}<br />
                <hr />
                <strong>€ {{ item.werkelijkresultaat }}</strong><br />
            </div>
        </td>
        <td  class="table__cell--default">
            <div>
                Percentage: {{ item.percentage }}%<br />
                <br />
                <br />
                <hr />
                <strong v-if="item.afwijking >= 0">€ {{item.afwijking}}</strong>
                <strong v-else class="text--red">€ {{item.afwijking}}</strong>
            </div>
        </td>
        <td  class="table__cell--default">
            <div>
                Stadagen: {{ item.stadagen }}<br />
                <br />
                <br />
                <hr />
                € {{ item.afschrijving }}
            </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
  props: {
    table_data: [Object, Array],
    table_style: [Number, String],
  },
  components: { ImageHover },
};
</script>
