<template>
  <table :class="`w-100 p-3 table--default table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Foto</th>
        <th class="table__head--text">Item</th>
        <th class="table__head--text"></th>
        <th class="table__head--text">Order</th>
        <th class="table__head--text">Werkelijk</th>
        <th class="table__head--text">Verschil</th>
        <th class="table__head--text">Stadagen</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
      >
        <td class="table__cell--default table--thumbnail">
          <img
            :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.foto}/original`"
            class="thumbnail"
          />
        </td>
        <td class="table__cell--default">
          <div>
            <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
            {{ item.soortnaam }}<br />
            {{ item.merknaam }}<br />
            {{ item.typenaam }}<br />
            {{ item.regjaar }}
          </div>
        </td>
        <td class="table__cell--default">
          <div>
            Accessoires<br />
            Afspraken<br />
            Transport weg<br />
            Extra / Zee<br />
            Documenten<br />
            Werkplaats<br />
            Spuitwerk<br />
            Commissie<br />
            <hr />
            <strong>Totaal: </strong>
          </div>
        </td>
        <td class="table__cell--default">
          <div>
            € {{ item.p_accessoires }}<br />
            € {{ item.p_afspraken }}<br />
            € {{ item.p_transport }}<br />
            € {{ item.p_extra }}<br />
            € {{ item.p_documenten }}<br />
            € {{ item.p_werkplaats }}<br />
            € {{ item.p_spuitwerk }}<br />
            € {{ item.p_commissie }}<br />
            <hr />
            <strong>€ {{ item.p_totaal }}</strong
            ><br />
          </div>
        </td>
        <td class="table__cell--default">
          <div>
            € {{ item.w_accessoires }}<br />
            € {{ item.w_afspraken }}<br />
            € {{ item.w_transportweg }}<br />
            € {{ item.w_transportzee }}<br />
            € {{ item.w_documenten }}<br />
            € {{ item.w_werkplaats }}<br />
            € {{ item.w_spuitwerk }}<br />
            € {{ item.w_commissie }}<br />
            <hr />
            <strong>€ {{ item.w_totaal }}</strong
            ><br />
          </div>
        </td>
        <td class="table__cell--default">
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <hr />
            <strong v-if="item.verschil >= 0">€ {{ item.verschil }}</strong>
            <strong v-else class="text--red">€ {{ item.verschil }}</strong
            ><br />
          </div>
        </td>
        <td class="table__cell--default">
          <div>
            Stadagen: {{ item.stadagen }}<br />
            Afschrijving: € {{ item.afschrijving }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
  props: {
    table_data: [Object, Array],
    table_style: [Number, String],
  },
  components: { ImageHover },
};
</script>