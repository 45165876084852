<template>
  <div :class="`m-3 wrapper wrapper--border wrapper--${this.bu}`">
    <div class="text-center p-2">
      <h2>Marge controle totaal</h2>
      <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Jaar: {{ year_number }} - Week: {{ week_number }}</span
      >
      <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <Tabs v-if="!loading && this.bu == 200">
      <Tab
        v-for="(seller, key) in margin_control_trucks"
        :title="key"
        :key="key"
        ><MarginControlTrucksTable :table_data="seller" :table_style="bu"
      /></Tab>
    </Tabs>
    <Tabs v-if="!loading && this.bu == 210">
      <Tab v-for="(seller, key) in margin_control_vans" :title="key" :key="key"
        ><MarginControlVansTable :table_data="seller" :table_style="bu"
      /></Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import moment from "moment";
import MarginControlVansTable from "@/components/MarginControlVansTable.vue";
import MarginControlTrucksTable from "@/components/MarginControlTrucksTable.vue";
import Loading from "@/components/Loading.vue";
import { subtractTime, addTime } from "@/functions/date.js";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: {
    Tabs,
    Tab,
    Loading,
    MarginControlVansTable,
    MarginControlTrucksTable,
  },
  data: () => ({
    full_date: null,
    year_number: null,
    week_number: null,
    margin_control_trucks: null,
    margin_control_vans: null,
    loading: true,
  }),
  created() {
    this.full_date = moment();
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.year_number = this.full_date.year();
      this.week_number = this.full_date.week() - 3;
      if (bu == 200 && !this.margin_control_trucks) {
        this.loading = true;
        request(
          `margin-control-trucks/${this.year_number}/${this.week_number}`,
          "GET"
        ).then(({ margin_control_trucks }) => {
          this.margin_control_trucks = margin_control_trucks;
          this.loading = false;
        });
      } else if (bu == 210 && !this.margin_control_vans) {
        this.loading = true;
        request(
          `margin-control-vans/${this.year_number}/${this.week_number}`,
          "GET"
        ).then(({ margin_control_vans }) => {
          this.margin_control_vans = margin_control_vans;
          this.loading = false;
        });
      }
    },

    previousWeek() {
      this.loading = true;
      this.margin_control_vans = null;
      this.margin_control_trucks = null;
      this.full_date = subtractTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },

    nextWeek() {
      this.loading = true;
      this.margin_control_vans = null;
      this.margin_control_trucks = null;
      this.full_date = addTime(1, this.full_date, "weeks");
      this.getData(this.bu);
    },
  },
};
</script>